import { I18NextModule } from "angular-i18next";
import { MarkdownModule } from "ngx-markdown";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";
import { CreateNewPasswordComponent } from "src/app/create-new-password/create-new-password.component";
import { FooterComponent } from "src/app/footer/footer.component";
import { HeaderComponent } from "src/app/header/header.component";
import { LoginPageComponent } from "src/app/login-page/login-page.component";
import { LoginErrorComponent } from "src/app/login-error/login-error.component";
import { TranslationsModule } from "src/app/translations.module";
import { SanitizePipe } from "src/app/utils/sanitize.pipe";
import { AppService } from "src/services/app.service";
import { FormInputService } from "src/services/form-input.service";
import { DateTimeService } from "src/services/date-time.service";
import {
    AnalyticsService,
    ButtonModule,
    CalloutModule,
    CardModule,
    ErrorModule,
    InputModule,
    LinkModule,
    LoaderModule,
    RequiredInputKeyModule
} from "@aileron/components";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AppInitModule } from "./app.init.module";
import { AppConfig } from "./app.config";
import {LoginNotificationComponent} from "./login-notification/login-notification.component";
import {MfaMiniMenuComponent} from "./mfa-mini-menu/mfa-mini-menu.component";
import {MfaPageComponent} from "./mfa-page/mfa-page.component";
import { MiniMenuComponent } from "./mini-menu/mini-menu.component";
import { UrlService } from "../services/url.service";
import { PingService } from "src/services/ping.service";
import { PingConfig } from "./ping.config";
import { A11yModule } from '@angular/cdk/a11y';

export function analyticsFactoryMethod(appConfig: AppConfig): AnalyticsService {
    const parms = {
        profile: "sec",
        environment: appConfig.tealiumConfig.tealium_environment
    };
    return new AnalyticsService(parms);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginPageComponent,
        CreateNewPasswordComponent,
        HeaderComponent,
        FooterComponent,
        SanitizePipe,
        LoginNotificationComponent,
        LoginErrorComponent,
        MiniMenuComponent,
        MfaMiniMenuComponent,
        MfaPageComponent
    ],
    imports: [
        A11yModule,
        ButtonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        I18NextModule,
        CalloutModule,
        RequiredInputKeyModule,
        ErrorModule,
        InputModule,
        LoaderModule,
        LinkModule,
        MarkdownModule.forRoot({ loader: HttpClient }),
        ReactiveFormsModule,
        TranslationsModule,
        CardModule,
        AppInitModule
    ],
    providers: [AppService, FormInputService, DateTimeService, AnalyticsService,
        UrlService, Title, PingService,
        { provide: AppConfig, useClass: AppConfig },
        { provide: PingConfig, useClass: PingConfig },
        { provide: AnalyticsService, useFactory: analyticsFactoryMethod, deps: [AppConfig] }
        ],
    bootstrap: [AppComponent],
})
export class AppModule {}
