import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PingConfig } from "./ping.config";
import { environment } from "src/environments/environment";
import { AppConfig } from "./app.config";
import { Router } from "@angular/router";

@Injectable()
export class PingInitService {

    userLocale: string
    aAdvantageNbr: string
    constructor(private httpClient: HttpClient,
        private pingConfig: PingConfig,
        private appConfig: AppConfig,
        private _injector: Injector
    ) { }

    private get _router() { return this._injector.get(Router); }

    async getPingConfig(): Promise<any> {
        if (window.location.search.substring(1).includes('locale')) {
            let urlLocale = this.parseUrl('locale');
            urlLocale = this.fixLocale(urlLocale);
            this.userLocale = urlLocale
        }

        if (window.location.href.indexOf('miniMenu') > -1) {
            if (!this.userLocale) {
                this.userLocale = this.fixLocale(navigator.language)
            }
            this.pingConfig.userConfig.locale = this.userLocale;
            return;
        }

        let flowId = this.parseUrl('flowId');
        if (!flowId) {
            this.pingConfig.userConfig.locale = this.userLocale;
            await this._router.navigate(["/error"], {queryParams: {locale: this.userLocale}, skipLocationChange: true });
            return;
        }

        const proxied = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async = true,
            user?: string,
            password?: string
        ) {
            proxied.apply(this, [method, url, async, user, password]);
        };

        let issuerUri = ""
        if (environment.env === "prod") {
            issuerUri = `./loyalty/pf-ws/authn/flows/${flowId}`
        } else if (environment.env === "qa") {
            issuerUri = `./loyalty/pf-ws/authn/flows/${flowId}`
        } else {
            issuerUri = `https://localhost:9031/loyalty/pf-ws/authn/flows/${flowId}`
        }

        await this.httpClient.get<any>(issuerUri, {
                headers: new HttpHeaders({
                    "X-XSRF-Header": "PingFederate"
                }),
            withCredentials: true
        })
            .toPromise().then(res => {
                this.pingConfig.userConfig = res;
                this.appConfig.tealiumConfig.client_name = this.mapClientName(this.pingConfig.userConfig.requestingClient);
                if (this.userLocale) {
                    this.pingConfig.userConfig.locale = this.userLocale
                } else {
                    if (this.pingConfig.userConfig && this.pingConfig.userConfig.locale) {
                        this.pingConfig.userConfig.locale = this.fixLocale(this.pingConfig.userConfig.locale)
                    } else {
                        this.pingConfig.userConfig.locale = "us-EN"
                    }
                }
                this.pingConfig.userConfig.aAdvantageNbr = res.aAdvantageNbr
            }).catch(async err => {
                this.pingConfig.userConfig.locale = this.userLocale;
                await this._router.navigate(["/error"], {queryParams: {locale:this.pingConfig.userConfig.locale}, skipLocationChange: true });
            });
    }

    private parseUrl(value: string): string {
        let found: string
        const query = window.location.search.substring(1);
        const params = query.split("&");

        for (let i = 0; i < params.length; i++) {
            const pos = params[i].indexOf("=");
            if (pos > 0) {
                const key = params[i].substring(0, pos);
                if (key === value) {
                    found = params[i].substring(pos + 1);
                }
            }
        }
        return found;
    }

    private fixLocale(locale: string) {
        locale = locale.replace("_", "-");
        return locale;
    }

    private mapClientName(clientName: string) {
        switch (clientName) {
            case "testclient":
                clientName = "sso test client";
                break;
            case "juniper":
                clientName = "juniper";
                break;
            case "privacyportal":
                clientName = "privacyportal";
                break;
            case "rocketmiles":
                clientName = "rocket miles";
                break;
            case "aavacations":
                clientName = "AAvacations";
                break;
            default:
                clientName;
        }
        return clientName;
    }

}
