import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppInitService } from "./app-init-service";


export function getTealiumConfig(appInitService: AppInitService) {
    return () => appInitService.getTealiumConfig();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: getTealiumConfig, deps: [AppInitService], multi: true }
  ]
})
export class AppInitModule { }
