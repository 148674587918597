import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MarkdownComponent, MarkdownService } from "ngx-markdown";
import { I18NEXT_SERVICE, I18NextPipe, ITranslationService } from "angular-i18next";
import { Router } from "@angular/router";
import { AnalyticsService } from "@aileron/components/services";
import { AppConfig } from "../app.config";
import { AppService } from "../../services/app.service";
import { DateTimeService } from "../../services/date-time.service";

@Component({
  selector: "app-mfa-mini-menu",
  templateUrl: "./mfa-mini-menu.component.html",
  styleUrls: ["./mfa-mini-menu.component.scss"]
})
export class MfaMiniMenuComponent implements OnInit {
    @ViewChild('headerTitle', { static: true }) headerTitle: ElementRef;



    private readonly PAGE_NAME = "sso choose verification delivery";
    private _maskedEmail = 'm**********aa.com';

    constructor(
      private markdownService: MarkdownService,
      private i18NextPipe: I18NextPipe,
      @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
      private router: Router,
      private analyticsService: AnalyticsService,
      private appConfig: AppConfig,
      public appService: AppService,
      private dateTimeService: DateTimeService
    ) {}

    ngAfterViewInit() {
        // Check if the element is focusable
        if(this.headerTitle.nativeElement) {
            // Set the focus
            this.headerTitle.nativeElement.focus();
        }
    }

    ngOnInit() {
        this.appService.setTitle(this.i18NextPipe.transform("common.title.verificationDelivery"));
        this.i18NextService.events.languageChanged.subscribe(newLocale => {
            this.appService.setTitle(this.i18NextPipe.transform("common.title.verificationDelivery"));
        });
        this.appConfig.tealiumConfig.site_language = this.i18NextService.language.substr(0,2).toUpperCase();
        this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
        const result: any = { page_name: this.PAGE_NAME, ...this.appConfig.tealiumConfig };
        this.analyticsService.view(result);
    }

    public get maskedEmail(): string {
        return this._maskedEmail;
    }
    public translateMessage(key: string, params: any = {}, isMarkdown = false): string {
        const options = {
            returnObjects: true,
            escapeValue: false,
            ...params  // adding interpolation parameters
        };

        if (isMarkdown) {
            return this.markdownService.compile(
                this.i18NextPipe.transform(key, options)
            );
        }
        return this.i18NextPipe.transform(key, options);
    }

    public returnToLogin() {
        this.router.navigate(["/login"]);
    }

    public sendVerificationCodeByEmail() {
        // Send verification code by email by email here
        this.router.navigate(["/mfaPage"]);
    }
}
