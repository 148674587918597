<div class="login-card">
    <h1 class="header">{{ translateMessage('login.subheader') }}</h1>

    <div class="enroll cell small-12">{{ translateMessage('login.notAMember') }} <a ail-link
            href="https://www.aa.com/loyalty/enrollment/enroll?locale={{ appService.getAALocale() }}"
            type="cta">{{ translateMessage('login.joinNow') }}</a>
    </div>

    <form [formGroup]="loginForm" id="loginFormId" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-12" role="alert">
                    <app-login-error
                        *ngIf="error"
                        [_errorCode]="errorCode">
                    </app-login-error>
                </div>

                <ail-required screenreader="required text" class="login-required-text">
                    {{ translateMessage('common.form.required') }}</ail-required>

                <div class="cell small-12">
                    <ail-input [ngClass]="{noMessageErrorClass: noMessageError && loginForm.invalid}"
                               controlName="username"
                               id="username"
                               name="username"
                               maxlength="52"
                               isType="text">
                        {{ translateMessage('login.form.fields.username.label') }} <span
                            class="show-for-sr">{{showForSr}}</span>
                        <ail-error hidden="true"
                            *ngIf=
                                "loginForm.get('username').invalid
                                && loginForm.get('username').touched
                                && !loginForm.get('username').errors.loginError">
                            {{ translateMessage('login.form.fields.username.errors.required') }}
                        </ail-error>
                        <ail-error hidden="true" class="noMessageError"
                           *ngIf="hasLoginError('username')">
                        </ail-error>
                    </ail-input>
                </div>

                <div class="cell small-12" *ngIf="!microsoftBrowser">
                    <div class="input-icons">
                        <ail-input [ngClass]="{noMessageErrorClass: noMessageError && loginForm.invalid}"
                                   controlName="password"
                                   id="password"
                                   name="password"
                                   maxlength="16"
                            [isType]="isTextFieldType ? 'text' : 'password'" >
                            {{ translateMessage('login.form.fields.password.label') }} <span
                                class="show-for-sr">{{showForSr}}</span>
                            <ail-error hidden="true"
                                *ngIf=
                                    "loginForm.get('password').errors?.required
                                    && loginForm.get('password').touched
                                    && !loginForm.get('password').errors.loginError">
                                {{ translateMessage('login.form.fields.password.errors.required') }}
                            </ail-error>
                            <ail-error hidden="true"
                                       *ngIf="hasLoginError('password')">
                            </ail-error>
                        </ail-input>
                        <i [ngClass]="{'display-password' : isTextFieldType, 'hidden-password' : !isTextFieldType}"
                           (click)="togglePasswordFieldType()" (keyup.Space)="togglePasswordFieldType()" tabindex="0" ></i>
                    </div>
                </div>

                <div class="cell small-12" *ngIf="microsoftBrowser">
                    <div class="input-icons">
                        <ail-input [ngClass]="{noMessageErrorClass: noMessageError && loginForm.invalid}"
                                   controlName="password"
                                   id="password"
                                   name="password"
                                   maxlength="16"
                            [isType]="isTextFieldType ? 'text' : 'password'" >
                            {{ translateMessage('login.form.fields.password.label') }} <span
                                class="show-for-sr">{{showForSr}}</span>
                            <ail-error hidden="true"
                                *ngIf=
                                    "loginForm.get('password').errors?.required
                                    && loginForm.get('password').touched
                                    && !loginForm.get('password').errors.loginError">
                                {{ translateMessage('login.form.fields.password.errors.required') }}
                            </ail-error>
                            <ail-error hidden="true"
                                       *ngIf="hasLoginError('password')">
                            </ail-error>
                        </ail-input>
                    </div>
                </div>

                <div class="cell small-12 login-button">
                    <button ailPrimaryButton type="submit" class="button primary expanded" name="_button_login"
                    aria-disabled="false" id="button_login_autofilled"
                    *ngIf="showAutoFillButton"
                    >{{ translateMessage('login.form.loginButton') }}
                    </button>
                    <button ailPrimaryButton type="submit" class="button primary expanded" name="_button_login"
                        aria-disabled="false" id="button_login"
                        *ngIf="!showAutoFillButton"
                        [disabled]="!loginForm.valid">{{ translateMessage('login.form.loginButton') }}
                    </button>
                </div>

                <div class="cell small-12 forgot-credentials-links">
                    <a ail-link type="cta"
                        [routerLink]="'/miniMenu'" [queryParams]="{locale: this.locale}">
                        {{ translateMessage('login.needHelp') }}</a>
                </div>

            </div>
        </div>
    </form>
</div>

