import { Injectable } from "@angular/core";
import { zonedTimeToUtc, utcToZonedTime, format as formatDate } from "date-fns-tz";
import { dateTimeFormat } from "src/config/analytics.config";

@Injectable()
export class DateTimeService {

    public now(format: string = dateTimeFormat): string {
        const ianaTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (!ianaTimeZone) {
            return formatDate(new Date(), format, { timeZone: "America/Chicago" });
        }
        const centralTime = this.convertDateToCentral(new Date(), ianaTimeZone);
        return formatDate(centralTime, format, { timeZone: "America/Chicago" });
    }

    private convertDateToCentral(date: Date, IANATimeZoneOfDate: string): Date {
        const utcDate = zonedTimeToUtc(date, IANATimeZoneOfDate);
        return utcToZonedTime(utcDate, "America/Chicago");
    }

}
