import {HttpErrorResponse} from "@angular/common/http";
import {AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MarkdownService } from "ngx-markdown";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from "@aileron/components/services";
import {PingService} from "../../services/ping.service";
import { AppConfig } from "../app.config";
import {I18NEXT_SERVICE, I18NextPipe, ITranslationService} from "angular-i18next";
import {UIError} from "../../interfaces/ui-error";
import {AppService} from "../../services/app.service";
import { DateTimeService } from "../../services/date-time.service";
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import {PingConfig} from "../ping.config";

@Component({
    selector: 'app-mfa-page',
    templateUrl: './mfa-page.component.html',
    styleUrls: ['./mfa-page.component.scss']
})
export class MfaPageComponent implements OnInit, AfterViewInit {
    @ViewChildren('digitInput') digitInputs: QueryList<ElementRef>;
    @ViewChild('submitButton', { static: true }) submitButton: ElementRef;

    private deviceId = '';
    private readonly PAGE_NAME = "sso enter verification code";
    mfaForm: FormGroup;
    private ERROR: UIError = null;
    private readonly DEFAULT = "LOGIN500";
    private _maskedEmail = '';
    public errorCode: string = null;
    public errorType: string = null;
    public notificationType: string = null;
    public notificationCode: string = null;
    public headerMessage = '';

    constructor(private markdownService: MarkdownService,
                private i18NextPipe: I18NextPipe,
                public appService: AppService,
                private analyticsService: AnalyticsService,
                private appConfig: AppConfig,
                @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
                private dateTimeService: DateTimeService,
                private liveAnnouncer: LiveAnnouncer,
                private pingService: PingService,
                private pingConfig: PingConfig,
                private router: Router,
                private route: ActivatedRoute) {}


    ngOnInit(): void {
        this.startMfaAuthenticate();
        this.route.params.subscribe(params => {
            // this.authUrl = params[''];
        });
        this.appService.isLoading = false;
        this.appService.setTitle(this.i18NextPipe.transform("common.title.enterVerificationCode"));
        this.i18NextService.events.languageChanged.subscribe(newLocale => {
            this.appService.setTitle(this.i18NextPipe.transform("common.title.enterVerificationCode"));
        });

        this.appConfig.tealiumConfig.site_language = this.i18NextService.language.substr(0,2).toUpperCase();
        this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
        const result: any = { page_name: this.PAGE_NAME, ...this.appConfig.tealiumConfig };
        this.analyticsService.view(result);

        this.initForm();
    }

    ngAfterViewInit(): void {
        this.digitInputs.toArray()[0].nativeElement.focus();
        // Use setTimeout to ensure the message is read after the input focus
        setTimeout(() => {
            this.liveAnnouncer.announce(this.translateMessage('mfa.enterVerificationCode'), 500);
        });
    }

    ngOnDestroy(): void {
        this.liveAnnouncer.clear();
    }

    private initForm(): void {
        this.mfaForm = new FormGroup({
            digit1: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            digit2: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            digit3: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            digit4: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            digit5: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            digit6: new FormControl('', [Validators.required, Validators.maxLength(1)])
        });
    }

    public get notification(): string {
        return this.notificationCode && this.notificationType;
    }

    public get error(): UIError {
        return this.ERROR;
    }

    public get maskedEmail(): string {
        return this._maskedEmail;
    }

    public set maskedEmail(value: string) {
        this._maskedEmail = value;
    }

    onInput(index: number, value: string): void {
        if (value.length > 1) {
            this.setValueAndMoveFocus(value, index);
        } else {
            this.moveFocus(index);
        }
    }

    private setValueAndMoveFocus(value: string, currentIndex: number): void {
        for (let i = 0; i < value.length; i++) {
            const controlName = `digit${currentIndex + i + 1}`;
            if (this.mfaForm.get(controlName)) {
                this.mfaForm.get(controlName).setValue(value[i]);
            }
        }
    }

    private moveFocus(index: number): void {
        if (index < this.digitInputs.length - 1) {
            setTimeout(() => {
                this.digitInputs.toArray()[index + 1].nativeElement.focus();
            }, 0);
        } else {
            setTimeout(() => {
                this.submitButton.nativeElement.focus();
            }, 0);
        }
    }

    onPaste(event: ClipboardEvent): void {
        // prevent the default paste action
        event.preventDefault();

        // get the pasted data as a string
        let pasteData = event.clipboardData.getData('text/plain');

        // split the string into individual characters
        let pasteDataArray = pasteData.split('');

        // set each form control's value to the corresponding character
        Object.keys(this.mfaForm.controls).forEach((controlName, index) => {
            if (pasteDataArray[index]) {
                this.mfaForm.get(controlName).setValue(pasteDataArray[index]);
            }
        });

        // after pasting, put the focus on the next empty input field or the submit button
        this.moveFocus(pasteDataArray.length - 1);
    }

    onFocus(index: number): void {
        const controlName = `digit${index + 1}`;
        this.mfaForm.get(controlName).setValue('');
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    public translateMessage(key: string, params: any = {}, isMarkdown = false): string {
        const options = {
            returnObjects: true,
            escapeValue: false,
            ...params  // adding interpolation parameters
        };

        if (isMarkdown) {
            return this.markdownService.compile(
                this.i18NextPipe.transform(key, options)
            );
        }
        return this.i18NextPipe.transform(key, options);
    }

    private resetFormFields(): void {
        // Clear all form values
        this.mfaForm.reset()

        // Set focus to the first input field
        setTimeout(() => {
            if (this.digitInputs && this.digitInputs.first) {
                this.digitInputs.first.nativeElement.focus();
            }
        });
    }
    public onResendCodeClick(): void {
        this.clearNotifications();
        this.notificationCode = 'mfa.resendCodeMsg';
        this.notificationType = 'success';
        this.resetFormFields();
        this.resendOtpCode();
    }

    clearNotifications(): void {
        this.ERROR = null;
        this.notificationCode = null;
        this.notificationType = null;
    }

    showErrorMessage(): void {
        this.errorCode = "MFA101"
        this.errorType = 'alert';
        this.ERROR = {
            message: `error:${this.errorCode}.message`,
        };
    }

    onSubmit(): void {
        if (this.mfaForm.valid) {
            const isSuccessfulVerification = false;
            this.clearNotifications();
            this.submitOneTimePassword();
        }
    }

    getControlsLength(): number {
        return Object.keys(this.mfaForm.controls).length;
    }

    getOtp(): number {
        const concatenatedValue: string = this.digitInputs
            .map((input: ElementRef) => input.nativeElement.value)
            .join('');

        return Number(concatenatedValue);
    }

    startMfaAuthenticate() {
        this.pingService.authenticate().subscribe(
            (result: any) => {
                this.pingConfig.userConfig = result;
                this.pingService.processCurrentStatus();
                this.ERROR = null;
                if (result.status !== 'OTP_REQUIRED') {
                    this.ERROR = {
                        message: `error:LOGIN500.message`
                    };
                    this.router.navigate(["/error"], { skipLocationChange: true })
                } else {
                    if (result && result.devices && result.devices[0]) {
                        this.maskedEmail = result.devices[0].target;
                        this.deviceId = result.devices[0].id;
                    }
                }
            },
            (e: HttpErrorResponse) => {
                const errorCode: string =
                    (e instanceof Error) || (!!e.error && !!e.error.code) ?
                        e.error.code as string : this.DEFAULT;

                this.ERROR = {
                    message: `error:${errorCode}.message`
                };
                this.router.navigate(["/error"], { skipLocationChange: true })
            }
        );

    }

    resendOtpCode() {
        this.pingService.resendOtpCode(this.deviceId).subscribe(
            (result: any) => {
                this.pingConfig.userConfig = result;
                this.pingService.processCurrentStatus();
                this.ERROR = null;
                if (result.status !== 'OTP_REQUIRED') {
                    this.ERROR = {
                        message: `resendOtpCode:error:LOGIN500.message`
                    };
                    this.router.navigate(["/error"], { skipLocationChange: true })
                }
                // else {
                //     if (result && result.devices && result.devices[0]) {
                //         this.maskedEmail = result.devices[0].target;
                //     }
                // }
            },
            (e: HttpErrorResponse) => {
                const errorCode: string =
                    (e instanceof Error) || (!!e.error && !!e.error.code) ?
                        e.error.code as string : this.DEFAULT;

                this.ERROR = {
                    message: `error:${errorCode}.message`
                };
                this.router.navigate(["/error"], { skipLocationChange: true })
            }
        );

    }

    submitOneTimePassword() {
        this.appService.isLoading = true;
        this.pingService.submitOneTimePassword(this.getOtp()).subscribe(
            (result: any) => {
                this.pingConfig.userConfig = result;
                this.pingService.processCurrentStatus();
                this.ERROR = null;
                if (result.status === 'MFA_COMPLETED') { // RESUME
                    this.completeAuthentication();
                } else {
                    this.appService.isLoading = false;
                    this.showErrorMessage();
                    this.resetFormFields();
                }
            },
            (e: HttpErrorResponse) => {
                this.appService.isLoading = false;
                const errorCode: string =
                    (e instanceof Error) || (!!e.error && !!e.error.code) ?
                        e.error.code as string : this.DEFAULT;
                if (errorCode === 'VALIDATION_ERROR') {
                    this.showErrorMessage();
                    this.resetFormFields();
                } else {
                    this.ERROR = {
                        message: `error:${errorCode}.message`
                    };
                    this.router.navigate(["/error"], { skipLocationChange: true })
                }
            }
        );

    }

    completeAuthentication() {
        this.pingService.completeAuthentication().subscribe(
            (result: any) => {
                this.pingConfig.userConfig = result;
                this.pingService.processCurrentStatus();
                this.ERROR = null;
                if (result.status === 'RESUME') {
                    window.location.href = result.resumeUrl;
                } else {
                    this.appService.isLoading = false;
                    this.ERROR = {
                        message: `error:LOGIN500.message`
                    };
                }
            },
            (e: HttpErrorResponse) => {
                this.appService.isLoading = false;
                const errorCode: string =
                    (e instanceof Error) || (!!e.error && !!e.error.code) ?
                        e.error.code as string : this.DEFAULT;

                this.ERROR = {
                    message: `error:${errorCode}.message`
                };
                this.router.navigate(["/error"], { skipLocationChange: true });
            }
        );

    }
}
