import {Component, Input, OnInit, SimpleChanges} from "@angular/core";
import {I18NextPipe} from "angular-i18next";
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: "app-login-notification",
  templateUrl: "./login-notification.component.html",
  styleUrls: ["./login-notification.component.scss"]
})
export class LoginNotificationComponent implements OnInit {
    @Input() public _notificationCode: string = null;
    @Input() public _notificationType = "success";
    private _notificationMessage: string = null;

    constructor(private i18NextPipe: I18NextPipe, private liveAnnouncer: LiveAnnouncer) {}

    ngOnInit() {
        this._notificationMessage = this.i18NextPipe.transform(this._notificationCode)
    }

    ngOnChanges(changes: SimpleChanges) {
        // Check if [_notificationCode] input has changed
        if (changes['_notificationCode'] && changes['_notificationCode'].currentValue) {
            // Use setTimeout to ensure the message is read after any other focus events
            setTimeout(() => {
                this.liveAnnouncer.announce(this.translateMessage(this._notificationCode), 500);
            });
        }
    }

    ngOnDestroy(): void {
        this.liveAnnouncer.clear();
    }

    get notificationMessage(): string {
        return this._notificationMessage;
    }

    public translateMessage(key: string): string {
        return this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false });
    }
}
