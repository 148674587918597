<div class="mini-menu-card">
    <h1 tabindex="-1" class="header" #headerTitle>{{translateMessage('mfa.miniMenu.verifyYourAccount')}}</h1>
    <p class="header-paragraph">{{translateMessage('mfa.miniMenu.headerParagraph')}}</p>
    <div class="menu-container">
        <a class="box-links-anchor" tabindex="0" (click)=sendVerificationCodeByEmail() href="javascript:void(0)">
            <div class="box-links">
                <span class="box-links-text">
                    <div>{{translateMessage('mfa.miniMenu.emailVerificationCode', {email: maskedEmail})}}</div>
                </span>
                <span class="box-links-forward-arrow">&gt;</span>
            </div>
        </a>
    </div>
    <div class="box-links-separator"></div>
    <div class="back-to-login-container">
        <a class="back-to-login" ail-link (click)=returnToLogin() href="javascript:void(0)">{{ translateMessage("miniMenu.backToLogin")}}</a>
    </div>
    <div class="back-to-login-separator"></div>
</div>
