<main id="main" class="main" role="main" aria-label="main content">
    <ail-loader *ngIf="isLoading">{{ "loading" | i18next }}</ail-loader>
    <div *ngIf="loadContent" class="content">
        <app-header></app-header>
        <router-outlet></router-outlet>
    </div>

    <div *ngIf="loadContent" class="footer">
        <app-footer></app-footer>
    </div>
</main>
