import { translations } from "./translations.values.module";

import {
    defaultInterpolationFormat,
    I18NEXT_SERVICE,
    I18NextModule,
    I18NextPipe,
    I18NextTitle,
    ITranslationService,
} from "angular-i18next";
import i18nextLanguageDetector from "i18next-browser-languagedetector";

import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { PingInitService } from "./ping-init-service";
import { PingConfig } from "./ping.config";

export const SUPPORTED_LOCALES = ["en-US", "es", "es-MX", "es-PE", "es-US", "fr", "pt", "pt-BR", "de", "it", "ja", "ko"];

const order = ["contentLocale", "PingDetector", "cookie", "htmlTag"];

const options = {
    lookupCookie: "sessionLocale",
    lookupQuerystring: "locale",
    order
};

export const i18nextOptions = {
    debug: false,
    resources: translations,
    defaultNS: "common",
    fallbackLng: "en-US",
    interpolation: {
        format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
    },
    ns: ["common", "error"],
    detection: options,
    supportedLngs: SUPPORTED_LOCALES,
    load: "currentOnly"
};

export const languageDetector = new i18nextLanguageDetector(null, options);

let userLocale: any;
export async function getLocale(pingService: PingInitService, pingConfig: PingConfig) {
    await pingService.getPingConfig().then(res => {
        try{
            userLocale = pingConfig.userConfig.locale ? pingConfig.userConfig.locale : 'en'
        } catch(e) {
            return userLocale = 'en'
        }
    });
}

// const detectQueryString = {
//     name: "QueryStringDetector",

//     lookup(options) {
//         let found;

//         if (typeof window !== "undefined") {
//             const query = window.location.search.substring(1);
//             const params = query.split("&");

//             for (let i = 0; i < params.length; i++) {
//                 const pos = params[i].indexOf("=");

//                 if (pos > 0) {
//                     const key = params[i].substring(0, pos);

//                     if (key === options.lookupQuerystring) {
//                         found = params[i].substring(pos + 1);
//                     }
//                 }
//             }
//             if (found && typeof found === "string") {
//                 found = [found.replace("_", "-")];
//             }
//         }
//         return 'ko';
//     }
// };

// languageDetector.addDetector(detectQueryString);

// export function appInit(i18next: ITranslationService) {
//     return () => i18next.use(languageDetector).init(i18nextOptions);
// }

export function localeIdFactory(i18next: ITranslationService): string {
    return i18next.language;
}

export function getPingConfig(pingInitService: PingInitService, pingConfig: PingConfig, i18next: ITranslationService): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise(async(resolve) => {
            await getLocale(pingInitService, pingConfig);
            const detectPingString = {
                name: 'PingDetector',
                lookup() {
                    return userLocale;
                }
            }
            languageDetector.addDetector(detectPingString);
            i18next.use(languageDetector).init(i18nextOptions)
            resolve(null);
        });
    };
}

export const I18N_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: getPingConfig,
        deps: [PingInitService, PingConfig, I18NEXT_SERVICE],
        multi: true,
    },
    {
        provide: Title,
        useClass: I18NextTitle,
    },
    {
        provide: LOCALE_ID,
        deps: [I18NEXT_SERVICE],
        useFactory: localeIdFactory,
    },
];

@NgModule({
    imports: [I18NextModule.forRoot()],
    providers: [PingInitService, I18N_PROVIDERS, I18NextPipe],
})
export class TranslationsModule { }
