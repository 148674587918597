import { Observable } from "rxjs";
import { Title } from "@angular/platform-browser"

import {HttpClient, HttpHeaders } from "@angular/common/http";
import {Inject, Injectable, LOCALE_ID} from "@angular/core";

import {DateTimeService} from "./date-time.service";
import { Router } from "@angular/router";

@Injectable()
export class AppService {
    public isLoading = false;

    public constructor(
        private httpClient: HttpClient,
        public dateTimeService: DateTimeService,
        @Inject(LOCALE_ID) public locale: string,
        private router: Router,
        private titleService: Title,
    ) {
    }

    public login<T>(data: any): Observable<T> {
        return this.post<T>("/login", data);
    }

    public createNewPassword<T>(data: any): Observable<T> {
        return this.post<T>("/createNewPassword", data);
    }

    // AA does not support locales with dashes
    public getAALocale() {
        return this.locale.replace("-", "_");
    }

    public getAnalyticsQueryParamForAALinks(): string {
        return "from=ssoLoginHelp";
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    private get<T>(uri: string): Observable<T> {
        const proxied = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async= true,
            user?: string,
            password?: string
        ) {
            proxied.apply(this, [method, url, async, user, password]);
        };
        return this.httpClient.get<T>(uri, {
            headers: new HttpHeaders({
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/x-www-form-urlencoded"
            })
        });
    }

    private post<T>(uri: string, payload: any | null): Observable<T> {
        const proxied = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async = true,
            user?: string,
            password?: string
        ) {
            proxied.apply(this, [method, url, async, user, password]);
        };

        return this.httpClient.post<T>(uri, payload, {
            headers: new HttpHeaders({
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/x-www-form-urlencoded",
            }),
        });
    }
}
