import { I18NEXT_SERVICE, I18NextPipe, ITranslationService } from "angular-i18next";
import { MarkdownService } from "ngx-markdown";

import { AppService } from "../../services/app.service";
import { Component, Inject, OnInit } from "@angular/core";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    constructor(
        private i18NextPipe: I18NextPipe,
        public appService: AppService,
        @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
        private markdownService: MarkdownService
    ) {}

    ngOnInit() {
        // no-op to avoid eslint warning
    }

    public translateMessage(key: string, isMarkdown = false): string {
        if (isMarkdown) {
            return this.markdownService.compile(
                this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false })
            );
        }
        return this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false });
    }
}
