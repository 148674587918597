<footer class="footer">
    <div class="grid-container">
        <div class="grid-x">
            <div class="small-12 links">
                <div class="grid-x login-footer-links">
                    <div class="cell small-4">
                        <a ail-link 
                            href="https://www.aa.com?locale={{ appService.getAALocale() }}">{{ translateMessage('login.footer.aacom') }}</a>
                    </div>
                    <div class="cell small-4">
                        <a ail-link 
                            href="https://www.aa.com/i18n/customer-service/support/privacy-policy.jsp?locale={{ appService.getAALocale() }}"
                            target="_blank" type="newWindow">
                            {{ translateMessage('login.footer.policy') }}
                        </a>
                    </div>
                    <div class="cell small-4">
                        <a ail-link
                            href="https://www.aa.com/i18n/aadvantage-program/aadvantage-terms-and-conditions.jsp?locale={{ appService.getAALocale() }}"
                            target="_blank" type="newWindow">
                            {{ translateMessage('login.footer.terms') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
