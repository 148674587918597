<div class="login-card">
    <h1 class="header">{{translateMessage('mfa.enterVerificationCode')}}</h1>
    <app-login-error
            *ngIf="error"
            [_errorCode]="errorCode">
    </app-login-error>
    <app-login-notification
            *ngIf="notification"
            [_notificationCode]="notificationCode" [_notificationType]="notificationType">
    </app-login-notification>
    <p class="verification-msg">
        {{translateMessage('mfa.verificationMessage', {email: maskedEmail})}}
    </p>
    <p class="verification-email">
        {{translateMessage('mfa.maskedEmail', {email: maskedEmail})}}
    </p>
    <form [formGroup]="mfaForm" (ngSubmit)="onSubmit()" class="input-container">
        <p>{{translateMessage('mfa.sixDigitVerificationCode')}}</p>
        <div class="inputs-row">
            <input
                    *ngFor="let digit of mfaForm.controls | keyvalue; let i = index"
                    type="text"
                    #digitInput
                    [formControlName]="digit.key"
                    (input)="onInput(i, $event.target.value)"
                    (paste)="onPaste($event)"
                    (focus)="onFocus(i)"
                    (keypress)="numberOnly($event)"
                    class="mfa-input"
                    inputmode="decimal"
                    [attr.aria-label]="translateMessage('mfa.inputField', { index: i + 1, total: getControlsLength() })"
            >
        </div>
        <a tabindex="0" class="resend-code" (click)="onResendCodeClick()" (keydown.enter)="onResendCodeClick()">{{translateMessage('mfa.resendCode')}}</a>
        <div class="button-row">
            <button [disabled]="!mfaForm.valid" #submitButton type="submit" class="button primary expanded">{{translateMessage('mfa.verify')}}</button>
        </div>
    </form>
    <p class="text-support-msg">{{translateMessage('mfa.incorrectEmail')}}
        <a ail-link
           href="https://www.aa.com/i18n/customer-service/contact-american/technical-support.jsp"
           type="cta">{{translateMessage('mfa.technicalSupport')}}</a>
    </p>
</div>
