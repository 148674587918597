<div class="mini-menu-card">
    <h1 class="header">{{ translateMessage("miniMenu.subheader") }}</h1>
    <div class="grid-container">
        <div class="grid-x grid-margin-x">
            <div class="cell small-12">
                <a ail-link href="https://www.aa.com/loyalty/needPasswordAccess?locale={{ appService.getAALocale() }}&{{ appService.getAnalyticsQueryParamForAALinks() }}">
                    <div class="box-links">
                        <span class="box-links-text">
                            {{ translateMessage("miniMenu.forgotPassword")}}
                        </span>
                        <span class="box-links-forward-arrow">&gt;</span>
                    </div>
                </a>
            </div>

            <div class="cell small-12">
                <a ail-link href="https://www.aa.com/aadvantage-program/need-your-number?locale={{ appService.getAALocale() }}&{{ appService.getAnalyticsQueryParamForAALinks() }}">
                 <div class="box-links">
                     <span class="box-links-text">
                        {{ translateMessage("miniMenu.forgotAdvantage")}}
                     </span>
                     <span class="box-links-forward-arrow">&gt;</span>
                 </div>
                </a>
            </div>

            <div class="cell small-12">
                <a ail-link href="https://www.aa.com/loyalty/needPasswordAccess?locale={{ appService.getAALocale() }}&{{ appService.getAnalyticsQueryParamForAALinks() }}">
                 <div class="box-links">
                     <span class="box-links-text">
                        {{ translateMessage("miniMenu.firstTimeLogging")}}
                     </span>
                     <span class="box-links-forward-arrow">&gt;</span>
                 </div>
                </a>
        </div>

            <div class="cell small-12">
                <a ail-link 
                    href="https://www.aa.com/loyalty/enrollment/enroll?locale={{ appService.getAALocale()}}&{{ appService.getAnalyticsQueryParamForAALinks() }}">
                 <div class="box-links">
                     <span class="box-links-text">
                        {{ translateMessage("miniMenu.notAdvMember")}}
                     </span>
                     <span class="box-links-forward-arrow">&gt;</span>
                 </div>
                </a>
            </div>
        </div>
        <div class="cell small-12 back-to-login" *ngIf="previousUrl">
            <a ail-link (click)=returnToLogin() href="javascript:void(0)">{{ translateMessage("miniMenu.backToLogin")}}</a>
        </div>
    </div>
</div>
