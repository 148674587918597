import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CreateNewPasswordComponent } from "./create-new-password/create-new-password.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import {MfaPageComponent} from "./mfa-page/mfa-page.component";
import { MiniMenuComponent } from "./mini-menu/mini-menu.component";
import { MfaMiniMenuComponent } from "./mfa-mini-menu/mfa-mini-menu.component";
import {LoginErrorComponent} from "./login-error/login-error.component";

export const routes: Routes = [
    { path: "login", component: LoginPageComponent },
    { path: "createNewPassword", component: CreateNewPasswordComponent },
    { path: "miniMenu", component: MiniMenuComponent },
    { path: "mfaMiniMenu", component: MfaMiniMenuComponent },
    { path: "mfaPage", component: MfaPageComponent },
    { path: "error", component:LoginErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
