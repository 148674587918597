import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import { MarkdownService } from "ngx-markdown";
import {I18NEXT_SERVICE, I18NextPipe, ITranslationService} from "angular-i18next";
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {AppService} from "../../services/app.service";

@Component({
    selector: "app-login-error",
    templateUrl: "./login-error.component.html",
    styleUrls: ["./login-error.component.scss"]
})
export class LoginErrorComponent implements OnInit {

    @Input() public _errorMessage: string = null;
    @Input() public _href ="";
    @Input() public _linkText = "";
    @Input() public _errorCode: string = null;
    public isLink = false;
    private err_Message_Regex = "\\[.+\\]\\(.+\\)"
    private recognizedErrors: string[] = ['LOGIN101','LOGIN201','LOGIN202','LOGIN203','LOGIN301','LOGIN302','LOGIN305','LOGIN500', 'MFA101'];

    constructor(private markdownService: MarkdownService,
                private i18NextPipe: I18NextPipe,
                private appService: AppService,
                private liveAnnouncer: LiveAnnouncer) {}

    ngOnInit(): void {
        // no-op to avoid eslint warning
    }

    ngOnDestroy(): void {
        this.liveAnnouncer.clear();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes._errorCode && changes._errorCode.currentValue) {
            if(!this.recognizedErrors.includes(this._errorCode)) {
                this._errorCode = 'LOGIN500';
            }
            this._errorMessage = this.translateMessage(`error:${this._errorCode}.message`);
            if (this._errorMessage) {
                this.parseErrorMessage();
            }
            // Announce error message.
            setTimeout(() => {
                this.liveAnnouncer.announce(this._errorMessage, 500);
            });
        }
    }

    get errorMessage(): string {
        return this._errorMessage;
    }

    public translateMessage(key: string, isMarkdown = false): string {
        if (isMarkdown) {
            return this.markdownService.compile(
                this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false })
            );
        }
        return this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false });
    }

    private parseErrorMessage(): void {
        const result = this.errorMessage.match(this.err_Message_Regex);
        this.isLink = result ? (result.length > 0): false as boolean
        if(this.isLink) {
            this._errorMessage = this._errorMessage.replace(result[0], "");
            this._linkText = result[0].split("](")[0].replace("[", "");
            this._href = result[0].split("](")[1].replace(")", "");
        }
    }

    public handleRedirectToAA() {
        window.location.replace(`https://www.aa.com/homePage.do?locale=${ this.appService.getAALocale() }`);
    }
}
