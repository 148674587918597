<div *ngIf="_errorCode" class="callout-small callout-small--alert ">
    <span [innerHTML]="_errorMessage"></span>
    <a *ngIf="isLink" ail-link type="cta" href={{_href}}>{{_linkText}}</a>
</div>

<div class="login-error-card" *ngIf="!_errorCode">
    <h1 class="header">{{ translateMessage('genericError.header') }}</h1>
    <div class="container">
        <p class="helpText">{{ translateMessage('genericError.helpText')}}</p>
        <ul class="error-list">
            <li>
                {{translateMessage('genericError.errorListItem1')}}
            </li>
            <li>
                {{translateMessage('genericError.errorListItem2')}}
            </li>
            <li>
                {{translateMessage('genericError.errorListItem3')}}
            </li>
            <li>
                {{translateMessage('genericError.errorListItem4')}}
            </li>
        </ul>
    </div>
    <div class="homeButtonCta">
        <button ailPrimaryButton type="submit" class="button primary expanded" name="home_button_cta"
                aria-disabled="false" id="button_login" (click)="handleRedirectToAA()">
            {{translateMessage('genericError.homeButtonCta')}}
        </button>
    </div>
</div>
