import { Component, Inject, OnInit } from "@angular/core";
import { MarkdownComponent, MarkdownService } from "ngx-markdown";
import { I18NEXT_SERVICE, I18NextPipe, ITranslationService } from "angular-i18next";
import { Router } from "@angular/router";
import { AnalyticsService } from "@aileron/components/services";
import { AppConfig } from "../app.config";
import { AppService } from "../../services/app.service";
import { DateTimeService } from "../../services/date-time.service";
import { UrlService } from "../../services/url.service";

@Component({
  selector: "app-mini-menu",
  templateUrl: "./mini-menu.component.html",
  styleUrls: ["./mini-menu.component.scss"]
})
export class MiniMenuComponent implements OnInit {

    private readonly PAGE_NAME = "sso login help";
    previousUrl: string = null;

    constructor(
      private markdownService: MarkdownService,
      private i18NextPipe: I18NextPipe,
      @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
      private router: Router,
      private analyticsService: AnalyticsService,
      private appConfig: AppConfig,
      public appService: AppService,
      private urlService: UrlService,
      private dateTimeService: DateTimeService
    ) {}

    ngOnInit() {
        this.appService.setTitle(this.i18NextPipe.transform("common.title.miniMenu"));
        this.i18NextService.events.languageChanged.subscribe(newLocale => {
            this.appService.setTitle(this.i18NextPipe.transform("common.title.miniMenu"));
        });
        this.urlService.previousUrl$
            .subscribe((previousUrl: string) => {
                this.previousUrl = previousUrl;
            });
        this.appConfig.tealiumConfig.site_language = this.i18NextService.language.substr(0,2).toUpperCase();
        this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
        const result: any = { page_name: this.PAGE_NAME, ...this.appConfig.tealiumConfig };
        this.analyticsService.view(result);
    }

    public translateMessage(key: string, isMarkdown = false): string {
        if (isMarkdown) {
            return this.markdownService.compile(
                this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false })
            );
        }
        return this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false });
    }

    returnToLogin() {
        this.router.navigateByUrl(this.previousUrl, { skipLocationChange: true })
    }
}
