import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { I18NextPipe, I18NEXT_SERVICE, ITranslationService } from "angular-i18next";
import { MarkdownService } from "ngx-markdown";
import * as PasswordConfiguration from "src/config/password.config";
import { HttpParamsOptions } from "src/interfaces/http-params-options.interface";
import { UIError } from "src/interfaces/ui-error";
import { AppService } from "src/services/app.service";
import { FormInputService } from "src/services/form-input.service";
import { AnalyticsService } from "@aileron/components";
import { AppConfig } from "../app.config";
import { DateTimeService } from "../../services/date-time.service";
import { PingService } from "src/services/ping.service";

@Component({
    selector: "app-create-new-password",
    templateUrl: "./create-new-password.component.html",
    styleUrls: ["./create-new-password.component.scss"],
})
export class CreateNewPasswordComponent implements OnInit {
    createPasswordForm: FormGroup;
    isTextFieldType = false;
    showForSr: string;
    isChromeBrowser: boolean = !!window["chrome"] && (!!window["chrome"]["webstore"] || !!window["chrome"]["runtime"]);
    isEdgeChromium: boolean = this.isChromeBrowser && navigator.userAgent.indexOf("Edg") > -1;
    isIE: boolean = /*@cc_on!@*/ false || !!document["documentMode"];
    isEdge: boolean = !this.isIE && !!window["StyleMedia"];
    microsoftBrowser: boolean;
    readonly PASSWORD_MIN_LEN: number = PasswordConfiguration.minLength;
    readonly PASSWORD_MAX_LEN: number = PasswordConfiguration.maxLength;
    readonly passwordPattern: string = PasswordConfiguration.pattern;
    public type = "alert";
    private ERROR: UIError = null;
    private readonly DEFAULT = "LOGIN500";
    public noMessageError = false;
    private readonly PASSWORD_PAGE = "sso create password";
    private PASSWORDERROR = {
        InvalidPassword: "LOGIN302",
        MissingPassword: "LOGIN301",
        NotAllowedPassword: "LOGIN305"
    };
    private readonly loginFailure = "loginfailure";

    constructor(
        private fb: FormBuilder,
        public appService: AppService,
        private formInputService: FormInputService,
        private i18NextPipe: I18NextPipe,
        @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
        private markdownService: MarkdownService,
        private analyticsService: AnalyticsService,
        private appConfig: AppConfig,
        private dateTimeService: DateTimeService,
        private pingService: PingService
    ) {}

    ngOnInit() {
        this.appService.setTitle(this.i18NextPipe.transform("common.title.newPassword"));
        this.i18NextService.events.languageChanged.subscribe(newLocale => {
            this.appService.setTitle(this.i18NextPipe.transform("common.title.newPassword"));
        });
        this.initcreatePasswordForm();
        this.isMicrosoftBrowser();
        this.appConfig.tealiumConfig.site_language = this.i18NextService.language.substr(0,2).toUpperCase();
        this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
        const result: any = { page_name: this.PASSWORD_PAGE, ...this.appConfig.tealiumConfig };
        this.analyticsService.view(result);
    }

    initcreatePasswordForm() {
        this.createPasswordForm = this.fb.group(
            {
                newPassword: ["", Validators.required],
                password: [""],
            },
            {
                validators: [this.matchingPasswordsValidator(this.formInputService),this.removeLoginError.bind(this)],
                updateOn: "change",
            }
        );
    }

    public get error(): UIError {
        return this.ERROR;
    }

    private get httpParamsOptions(): HttpParamsOptions {
        const password: string = this.createPasswordForm.get("password").value as string;
        const confirmPassword: string = this.createPasswordForm.get("newPassword").value as string;
        return {
            fromObject: {
                password,
                confirmPassword,
            },
        };
    }

    onSubmit() {
        this.showLoader();
        if (this.createPasswordForm.invalid) {
            return;
        }

        this.createNewPassword();

    }

    createNewPassword() {
           const {password, confirmPassword} = this.httpParamsOptions.fromObject;
           this.pingService.updatePassword(password , confirmPassword).subscribe(
            (result: any) => {
                this.ERROR = null;
                if (result.status === 'RESUME') {
                window.location.href = result.resumeUrl;
                }
            },
            (e: HttpErrorResponse) => {
                const errorCode: string =
                    (e instanceof Error) || (!!e.error && !!e.error.code) ?
                    e.error.code as string : this.DEFAULT;

                this.ERROR = {
                    message: `error:${errorCode}.message`
                };

                if(errorCode === this.PASSWORDERROR.InvalidPassword || errorCode === this.PASSWORDERROR.MissingPassword
                    || errorCode === this.PASSWORDERROR.NotAllowedPassword) {
                    this.setLoginError(true);
                }
                this.appService.isLoading = false;
                this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
                const result: any = { error_id: errorCode,
                    login_value: this.loginFailure,
                    page_name: this.PASSWORD_PAGE,
                    ...this.appConfig.tealiumConfig };
                this.analyticsService.link(result);

            }
        );

    }

    public translateMessage(key: string, isMarkdown = false): string {
        if (isMarkdown) {
            return this.markdownService.compile(
                this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false })
            );
        }
        return this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false });
    }

    togglePasswordFieldType() {
        this.isTextFieldType = !this.isTextFieldType;
    }

    isMicrosoftBrowser() {
        if (this.isEdgeChromium === true || this.isIE === true || this.isEdge === true) {
            this.microsoftBrowser = true;
        }
    }

    public matchingPasswordsValidator(formInputService: FormInputService): ValidatorFn {
        return function (formGroup: FormGroup): ValidationErrors | null {
            const confirmPasswordControl: FormControl = formGroup.get("password") as FormControl;
            const match: boolean = formInputService.fieldsMatch(formGroup);
            const errors = match ? null : { ...confirmPasswordControl.errors, unmatchedPasswords: true };
            confirmPasswordControl.setErrors(errors, { emitEvent: true });
            return errors;
        };
    }

    public isNewPasswordRequiredError(): boolean {
        const control: FormControl = this.createPasswordForm.get("newPassword") as FormControl
        return this.formInputService.isRequired(control) as boolean;
    }

    public isNewPasswordInvalidError(): boolean {
        const control: FormControl = this.createPasswordForm.get("newPassword") as FormControl;
        return this.formInputService.isInvalid(control) as boolean;
    }

    public isConfirmPasswordInvalidError(): boolean {
        const control: FormControl = this.createPasswordForm.get("password") as FormControl;

        const error: boolean = (
            (this.formInputService.isTouched(control) && this.formInputService.hasError(control))
        ) as boolean;
        return error;
    }

    showLoader() {
        this.appService.isLoading = true;

        setTimeout(() => {
          this.appService.isLoading = false;
        }, 120000);
    }

    setLoginError(errorStatus: boolean): void {
        if (errorStatus) {
            this.createPasswordForm
                .get("newPassword").setErrors({ ...this.createPasswordForm.errors, validationError: true },{ emitEvent: true });
            this.noMessageError = true;
        } else {
            const newPasswordErrors = this.createPasswordForm.get("newPassword").errors;
            const passwordErrors = this.createPasswordForm.get("password").errors;

            const newPassword = this.createPasswordForm.get("newPassword");
            const password = this.createPasswordForm.get("password");

            if (newPasswordErrors && newPasswordErrors.validationError) {
                delete newPasswordErrors.validationError;
                newPassword.setErrors(null, { emitEvent: true });
            }
            if (passwordErrors && passwordErrors.validationError) {
                delete passwordErrors.validationError;
                password.setErrors(null, { emitEvent: true });
            }
            this.noMessageError = false;
        }
    }

    removeLoginError(control: AbstractControl): null {
        const newPasswordErrors = control.get("newPassword").errors;
        const passwordErrors = control.get("password").errors;

        const newPassword = control.get("newPassword");
        const password = control.get("password");

        if (newPasswordErrors && newPasswordErrors.validationError) {
            newPassword.setErrors(null);
        }
        if (passwordErrors && passwordErrors.validationError) {
            password.setErrors(null);
        }
        this.noMessageError = false;
        return null;
    }
}