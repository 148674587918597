<div class="new-password-card">
    <h1 class="header">{{ translateMessage("createNewPassword.subheader") }}</h1>

    <form [formGroup]="createPasswordForm" id="createPasswordFormId" (ngSubmit)="onSubmit()">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-12">
                    <p class="callout-small callout-small--alert" role="alert" *ngIf="error">
                        {{ translateMessage(error.message) }}
                    </p>
                </div>

                <div class="cell small-12 requirements-cell">
                    <div class="requirements-text-cell">
                        <p class="requirements-header">{{ translateMessage("createNewPassword.requirements.header") }}</p>
                        <ul class="requirements-bullet-points">
                            <li>
                                {{ translateMessage("createNewPassword.requirements.length") }}
                            </li>
                            <li>
                                {{ translateMessage("createNewPassword.requirements.specialCharacters") }}
                            </li>
                            <li>
                                <a ail-link
                                   href="https://www.aa.com/i18n/customer-service/faqs/aadvantage-faqs.jsp?locale={{ appService.getAALocale() }}" target="_blank" type="newWindow">
                                    {{translateMessage("createNewPassword.requirements.acceptedLink")}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <ail-required screenreader="required text" class="login-required-text">
                    {{ translateMessage("common.form.required") }}
                </ail-required>

                <!-- COMMON-BROWSERS -->
                <div class="cell small-12" *ngIf="!microsoftBrowser">
                    <div class="input-icons">
                        <i
                            [ngClass]="{
                                'display-password': isTextFieldType,
                                'hidden-password': !isTextFieldType
                            }"
                            (click)="togglePasswordFieldType()"
                        ></i>
                        <ail-input
                            controlName="newPassword"
                            id="newPassword"
                            name="newPassword"
                            [isType]="isTextFieldType ? 'text' : 'password'"
                            minlength="6"
                            maxlength="16"
                            required
                            [pattern]="passwordPattern"
                        >
                            {{ translateMessage("createNewPassword.form.fields.newPassword.label") }}
                            <span class="show-for-sr">{{ showForSr }}</span>
                            <ail-error
                                hidden="true"
                                *ngIf="isNewPasswordRequiredError()"
                            >
                                {{ translateMessage("createNewPassword.form.fields.newPassword.errors.emptyPassword") }}
                            </ail-error>
                            <ail-error
                                hidden="true"
                                *ngIf="isNewPasswordInvalidError()"
                            >
                                {{ translateMessage("createNewPassword.form.fields.newPassword.errors.invalidPassword") }}
                            </ail-error>
                        </ail-input>
                    </div>
                </div>

                <!-- COMMON-BROWSERS -->
                <div class="cell small-12 first-input-field" *ngIf="!microsoftBrowser">
                    <ail-input controlName="password" id="password" name="password" isType="password" required>
                        {{ translateMessage("createNewPassword.form.fields.confirmPassword.label") }}
                        <span class="show-for-sr">{{ showForSr }}</span>
                        <ail-error
                            hidden="true"
                            *ngIf="isConfirmPasswordInvalidError()"
                        >
                            {{
                                translateMessage(
                                    "createNewPassword.form.fields.confirmPassword.errors.unmatchedPassword"
                                )
                            }}
                        </ail-error>
                    </ail-input>
                </div>

                <!-- IE-BROWSER -->
                <div class="cell small-12" *ngIf="microsoftBrowser">
                    <div class="input-icons">
                        <ail-input
                            controlName="newPassword"
                            id="newPassword"
                            name="newPassword"
                            [isType]="isTextFieldType ? 'text' : 'password'"
                            minlength="6"
                            maxlength="16"
                            required
                            [pattern]="passwordPattern"
                        >
                            {{ translateMessage("createNewPassword.form.fields.newPassword.label") }}
                            <span class="show-for-sr">{{ showForSr }}</span>
                            <ail-error
                                hidden="true"
                                *ngIf="isNewPasswordRequiredError()"
                            >
                                {{ translateMessage("createNewPassword.form.fields.newPassword.errors.emptyPassword") }}
                            </ail-error>
                            <ail-error
                                hidden="true"
                                *ngIf="isNewPasswordInvalidError()"
                            >
                                {{ translateMessage("createNewPassword.form.fields.newPassword.errors.invalidPassword") }}
                            </ail-error>
                        </ail-input>
                    </div>
                </div>

                <!-- IE-BROWSER -->
                <div class="cell small-12" *ngIf="microsoftBrowser">
                    <div class="input-icons">
                        <ail-input controlName="password" id="password" name="password" isType="password" required>
                            {{ translateMessage("createNewPassword.form.fields.confirmPassword.label") }}
                            <span class="show-for-sr">{{ showForSr }}</span>
                            <ail-error
                                hidden="true"
                                *ngIf="isConfirmPasswordInvalidError()"
                            >
                            {{
                                translateMessage(
                                    "createNewPassword.form.fields.confirmPassword.errors.unmatchedPassword"
                                )
                            }}
                            </ail-error>
                        </ail-input>
                    </div>
                </div>

                <div class="cell small-12 continue-button">
                    <button
                        ailPrimaryButton
                        type="submit"
                        class="button primary expanded"
                        name="_button_login"
                        aria-disabled="false"
                        id="button_login"
                        [disabled]="!createPasswordForm.valid"
                    >
                        {{ translateMessage("createNewPassword.form.submit") }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
