import { Injectable } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors } from "@angular/forms";

@Injectable()
export class FormInputService {

    public isTouched(control: FormControl): boolean {
        return (control.touched || control.dirty) as boolean;
    }

    public isEmpty(control: FormControl): boolean {
        const value: string = control.value || "";
        return (value === "") as boolean;
    }

    public isRequired(control: FormControl): boolean {
        return (this.isTouched(control) && this.isEmpty(control)) as boolean;
    }

    public hasError(control: FormControl): boolean {
        const errors: ValidationErrors = control.errors || {};
        return !!Object.keys(errors).length as boolean;
    }

    public isInvalid(control: FormControl): boolean {
        return (this.isTouched(control) && !this.isEmpty(control) && this.hasError(control)) as boolean;
    }

    public fieldsMatch(formGroup: FormGroup): boolean {
        const values: string[] = Object.values(formGroup.value).map((value: never): string => value || "") as string[];

        return values.every((value: string): boolean => {
            return values.reduce((result: boolean, other: string): boolean => {
                return (result && value === other) as boolean;
            }, true);
        }) as boolean;
    }
}
