import {  Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import * as staticGlobalWA from "src/assets/webAnalytics/global_data.json";
import { AppConfig } from "./app.config";
import { version } from "src/version";
import {environment} from "../environments/environment";

@Injectable()
export class AppInitService {

  constructor(private httpClient: HttpClient,
    private appConfig: AppConfig) { }
  async getTealiumConfig(): Promise<any> {

    const proxied = XMLHttpRequest.prototype.open;

    XMLHttpRequest.prototype.open = function (
        method: "delete" | "get" | "post" | "put",
        url: string,
        async = true,
        user?: string,
        password?: string
    ) {
        proxied.apply(this, [method, url, async, user, password]);
    };
   await this.httpClient.get("/", {
          observe: "response",
          responseType: 'text',
          headers: new HttpHeaders({
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded"
          })
      })
      .toPromise().then(res =>{
      let env;
      if (environment.production) {
          env = 'prod'
      } else {
          env = 'qa'
      }
      this.appConfig.tealiumConfig = {
                //TODO: set tealium_environment back to env variable. This is for integration test between aavacation and login spa
                // @ts-ignore
                ...staticGlobalWA.default,
                site_country: res ? res.headers.get("x-akamai-country-code") : "",
                true_client_ip: res ? res.headers.get("true-client-ip") : "",
                tealium_environment: env,
                tealium_profile: "sec",
                app_build_version: version.string,
                app_region: "netStorage"
            };
      }).catch(err => {
        // eat it, not much we can do
      });



  }
}