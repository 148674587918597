import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, Component, Directive, ElementRef, Inject, OnInit, Renderer2, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { I18NextPipe, I18NEXT_SERVICE, ITranslationService } from "angular-i18next";
import { MarkdownService } from "ngx-markdown";
import { UIError } from "../../interfaces/ui-error";
import { AppService } from "../../services/app.service";
import { AbstractControl } from "@angular/forms";
import { Router } from "@angular/router";
import { AnalyticsService } from "@aileron/components/services";
import { AppConfig } from "../app.config";
import { DateTimeService } from "src/services/date-time.service";
import { PingService } from "src/services/ping.service";
import { PingConfig } from "../ping.config";
import { UrlService } from "src/services/url.service";

@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit, AfterViewInit {
    showAutoFillButton: any = false;
    currentUrl: string = null;
    loginForm: FormGroup;
    isTextFieldType = false;
    showForSr: string;
    locale: string
    isChromeBrowser: boolean = !!window["chrome"] && (!!window["chrome"]["webstore"] || !!window["chrome"]["runtime"]);
    isEdgeChromium: boolean = this.isChromeBrowser && navigator.userAgent.indexOf("Edg") > -1;
    isIE: boolean = /*@cc_on!@*/ false || !!document["documentMode"];
    isEdge: boolean = !this.isIE && !!window["StyleMedia"];
    microsoftBrowser: boolean;
    public type = "alert";
    private ERROR: UIError = null;
    public errorCode: string = null;
    public noMessageError = false;
    private readonly DEFAULT = "LOGIN500";
    private readonly loginFailure = "loginfailure";
    private readonly PAGE_NAME = "sso login";

    constructor(
        private fb: FormBuilder,
        public appService: AppService,
        private i18NextPipe: I18NextPipe,
        @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
        private router: Router,
        private markdownService: MarkdownService,
        private analyticsService: AnalyticsService,
        private appConfig: AppConfig,
        private dateTimeService: DateTimeService,
        private pingService: PingService,
        private pingConfig: PingConfig,
        private elementRef: ElementRef,
        private urlService: UrlService
    ) {}

    ngOnInit() {
        this.locale = this.pingConfig.userConfig.locale;
        this.appService.setTitle(this.i18NextPipe.transform("common.title.login"));
        this.i18NextService.events.languageChanged.subscribe(newLocale => {
            this.appService.setTitle(this.i18NextPipe.transform("common.title.login"));
        });
        this.initLoginForm();
        this.isMicrosoftBrowser();
        this.appConfig.tealiumConfig.site_language = this.i18NextService.language.substr(0,2).toUpperCase();
        this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
        const result: any = { page_name: this.PAGE_NAME, ...this.appConfig.tealiumConfig };
        this.analyticsService.view(result);

        this.currentUrl = this.router.routerState.snapshot.url;
        this.urlService.setPreviousUrl(this.currentUrl);
    }

    // While on the login component, we listen in on click and tab events
    // For this autofill work-around, for any user interaction (click anywhere on the application),
    // we show the original button for the rest of this components life
    // the timeout is to allow the substitute button to live long enough to fire the submit function
    @HostListener('document:click', ['$event'])
    @HostListener('document:keydown.tab', ['$event'])
    handlerUserInputFunction(e: MouseEvent) {
        setTimeout(() => {
            if(this.showAutoFillButton === true){
                this.showAutoFillButton = false
            }
          }, 50);
    }

    // This autofill work around attempts to scan the username and password input box for a change in the back-ground color
    // We will then show an alternate, always-enabled button if we detect autofill colors.
    // The original submit button with the validators attached returns when the user clicks anywhere (see above hostlistener)
    // This check happens every 1/10 second, for 1.5 seconds.
    // Chrome, Edge browsers use rgb(232. 240, 254)
    // Firefox does not have an autofill issue in the first place.
    // Safari does not autofill without any user input.
    ngAfterViewInit(){
        const chromiumColor = 'rgb(232, 240, 254)'
        const element: HTMLElement = this.elementRef.nativeElement;
		const input = element.querySelectorAll("input");
        // TODO update the code so the rule isn't disabled
        // eslint-disable-next-line angular/interval-service
        let autoFillCheck = setInterval(() => {
            let usrColor = window.getComputedStyle(input[0]).backgroundColor
            let pwdColor = window.getComputedStyle(input[1]).backgroundColor
            if(usrColor == chromiumColor && pwdColor == chromiumColor){
                this.showAutoFillButton = true;
            }
        }, 100);
        setTimeout(() => {
            clearInterval(autoFillCheck);
        }, 1500);
    }

    public hasLoginError(controlName: string): boolean {
        return (this.loginForm.get(controlName).errors && this.loginForm.get(controlName).errors.loginError) as boolean;
    }

    setLoginError(errorStatus: boolean): void {
        if (errorStatus) {
            this.loginForm.get("username").setErrors({ ...this.loginForm.errors, loginError: true },{ emitEvent: true });
            this.loginForm.get("password").setErrors({ ...this.loginForm.errors, loginError: true },{ emitEvent: true });
            this.noMessageError = true;
        } else {
            const usernameErrors = this.loginForm.get("username").errors;
            const passwordErrors = this.loginForm.get("password").errors;

            const username = this.loginForm.get("username");
            const password = this.loginForm.get("password");

            if (usernameErrors && usernameErrors.loginError) {
                delete usernameErrors.loginError;
               username.setErrors(null, { emitEvent: true });
            }
            if (passwordErrors && passwordErrors.loginError) {
                delete passwordErrors.loginError;
                password.setErrors(null, { emitEvent: true });
            }
            this.noMessageError = false;
        }
    }

    removeLoginError(control: AbstractControl): null {
        const usernameErrors = control.get("username").errors;
        const passwordErrors = control.get("password").errors;

        const username = control.get("username");
        const password = control.get("password");

        if (usernameErrors && usernameErrors.loginError) {
            username.setErrors(null);
        }
        if (passwordErrors && passwordErrors.loginError) {
            password.setErrors(null);
        }
        this.noMessageError = false;
        return null;
    }

    initLoginForm() {
        this.loginForm = this.fb.group({
            username: ["", [Validators.required]],
            password: ["", Validators.required]
        }, {
            validators: this.removeLoginError.bind(this),
            updateOn: "change"
        });
        this.noMessageError = false;
        if (this.pingConfig.userConfig.aAdvantageNbr) {
            this.loginForm.get("username").setValue(this.pingConfig.userConfig.aAdvantageNbr);
        }
    }

    public get error(): UIError {
        return this.ERROR;
    }

    onSubmit() {
        this.showLoader();
        if (this.loginForm.invalid) {
            return;
        }

        this.loginForm.markAllAsTouched();
        this.errorCode = null;
        this.ERROR = null;

        this.callLoginService();

    }

    showLoader() {
        this.appService.isLoading = true;

        setTimeout(() => {
          this.appService.isLoading = false;
        }, 120000);
    }

    callLoginService() {
        const tempUserName: string = (this.loginForm.get("username").value || "") as string;
        const password: string = (this.loginForm.get("password").value || "") as string;

        this.pingService.submitCredentials(tempUserName, password).subscribe(
            (result: any) => {
                this.pingConfig.userConfig = result;
                this.pingService.processCurrentStatus();
                this.errorCode = null;
                this.ERROR = null;
                if (result.status === 'RESUME') {
                    window.location.href = result.resumeUrl;
                }
            },
            (e: HttpErrorResponse) => {
                this.errorCode =
                    (e instanceof Error) || (!!e.error && !!e.error.code) ?
                    e.error.code as string : this.DEFAULT;

                if(!this.errorCode.includes('LOGIN')) {
                    this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
                    const result: any = { error_id: this.errorCode,
                        login_value: this.loginFailure,
                        page_name: this.PAGE_NAME,
                        ...this.appConfig.tealiumConfig };
                    this.analyticsService.link(result);
                    this.router.navigate(["/error"], { skipLocationChange: true });
                    return;
                }

                this.ERROR = {
                    message: `error:${this.errorCode}.message`,
                };

                this.appService.isLoading = false;

                if(this.errorCode === "LOGIN101" || this.errorCode === "LOGIN201") {
                    this.setLoginError(true);
                }
                else if(this.errorCode === "LOGIN002") {
                    this.router.navigate(["createNewPassword"], { skipLocationChange: true });
                    this.noMessageError = false;
                }
                else {
                    this.noMessageError = false;
                }
                this.appConfig.tealiumConfig.time_stamp = this.dateTimeService.now();
                const result: any = { error_id: this.errorCode,
                    login_value: this.loginFailure,
                    page_name: this.PAGE_NAME,
                    ...this.appConfig.tealiumConfig };
                this.analyticsService.link(result);
            }
        );
    }

    public translateMessage(key: string, isMarkdown = false): string {
        if (isMarkdown) {
            return this.markdownService.compile(
                this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false })
            );
        }
        return this.i18NextPipe.transform(key, { returnObjects: true, escapeValue: false });
    }

    togglePasswordFieldType() {
        this.isTextFieldType = !this.isTextFieldType;
    }

    isMicrosoftBrowser() {
        if (this.isEdgeChromium === true || this.isIE === true || this.isEdge === true) {
            this.microsoftBrowser = true;
        }
    }
}
